import React from 'react'
import { useState, useEffect, useRef, createRef } from 'react'
import { navigate } from 'gatsby';

import Container from '../pages/container/container';

const initialFormData = Object.freeze({
    name: '',
    job_title: '',
    email_address: '',
    contact_number: '',
    company_website: '',
    industry_sector: '',
    domain: '',
    expertise: '',
    interests: [],
})

const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
    return (
        <input type={type} name={name} checked={checked} onChange={onChange} />
    );
};

function UserAccountForm() {
    const [formData, setFormData] = useState(initialFormData)
    const [currentStep, setCurrentStep] = useState(1)
    const [formStatus, setFormStatus] = useState("standby")
    const [formAnimation, setFormAnimation] = useState("slide-left")
    const [interests, setInterests] = useState({})
    const [step1, setStep1] = useState("");
    const [step2, setStep2] = useState("");
    const [step3, setStep3] = useState("");

    const handleCheckboxChange = (e) => {
        setInterests({
            ...interests,
            [e.target.name]: e.target.checked
        });
        //console.log("checkedItems: ", checkedItems);
    };

    const checkboxes = [
        {
            name: "check-box-1",
            key: "checkBox1",
            label: "Check Box 1"
        },
        {
            name: "check-box-2",
            key: "checkBox2",
            label: "Check Box 2"
        }
    ];

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const nextStep = e => {
        if (step1 == "clear") {
            setCurrentStep(currentStep + 1);
        } else if (step1 == "clear") {
            setCurrentStep(currentStep + 1);
        }
    }

    const prevStep = e => {
        setCurrentStep(currentStep - 1);
    }

    const combineFormData = e => {
        setFormData({
            ...formData,
            interests: interests
        })
    }

    async function submitForm(e) {
        const response = await fetch("https://app.4pointtkd-members.com/api/application", {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "omit",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrer: "client",
            body: JSON.stringify(formData),
        });

        const json = await response.json();
        //console.log(json);
        if (json.status == "Success") {
            navigate("/login");
        }
    }

    useEffect(() => {
        if (formData.name != "" && formData.job_title != "") {
            setStep1("clear");
        }

    }, [formData])

    return (
        <>

            <div className="col-lg-3">
                <h2> Settings</h2>
                <ul className="account-list">
                    <li>
                        Account
                    </li>
                    <li>
                        Password
                    </li>
                    <li>
                        Subscription
                    </li>
                </ul>
            </div>
            <div className="col-lg-3">
                <h3>Account</h3>
            </div>

        </>
    );
}

export default UserAccountForm
